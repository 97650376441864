html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "PhosphateInline";
  src: url("./Phosphate-Inline.ttf") format("truetype");
}

@font-face {
  font-family: "PhosphateSolid";
  src: url("./Phosphate-Solid.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html {
  scrollbar-width: none !important;
  /* overflow-y: hidden;
  overscroll-behavior-y: none; */
  overflow-x: hidden;
  min-height: 100vh;
}

#root {
  height: 100%;
}

body {
  /* overflow-y: hidden;
  overscroll-behavior-y: none; */
  height: 100vh;
  height: calc(var(--app-height, 100vh));
  overflow-x: hidden;
  background-color: #3b4b5a;
}

input,
textarea,
button,
label,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-decoration: unset;
}

div::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
section::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
